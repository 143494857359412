
.home {
    display: flex;
    flex-direction: column;

    &__featured {
        width: 100%;
        // min-height: 100%;
        margin-top: 50px;
        margin-bottom: 40px;
    }
}