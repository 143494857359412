@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

.slick-prev:before, .slick-next:before {
    background-color: rgb(187, 184, 184);
    border-radius: 10px;
}
.slick-slider {
    width: calc(100% - 80px);
}


.input__numeric {
    position: relative;

    vertical-align: top;
    white-space: nowrap;

    & span, button {
        display: inline-block;
        margin: 0;
        vertical-align: top;
        box-shadow: none;
    }
    & span {
        width: 35px;
        height: 39px;
        line-height: 39px;
        padding: 0 5px;
        text-align: center;
        background-color: transparent;
        font-size: 14px;
        color: #5E6977;

        border: 2px solid #E1E8EE;

        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            margin: 0;
        }
    }
    &__minus, &__plus {
        padding: 7px 10px 8px;
        height: 43px;
        background-color: #ffffff;

        border: 2px solid #E1E8EE;
        border-radius: 6px;

        cursor: pointer;

        &:hover {
            background: #eeeeee;
        }
        &:focus {
            outline: none;
        }
    }
    &__minus {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    &__plus {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.js-image-zoom__zoomed-image {
    z-index: 10;
}

.product {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    overflow-x: hidden;
    overflow-y: scroll;

    &__info {
        display: flex;
        flex-direction: row;

        max-width: 1200px;
        width: 100%;
        // min-height: 100%;

        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin-bottom: 40px;

        &__left {
            display: flex;
            position: relative;
            justify-content: center;
            width: 50%;
            padding: 40px;

            & > img {
                width: 100%;
                height: 100%;
            }
        }
        &__right {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 50%;
        }
        &__desc {
            border-bottom: 1px solid #E1E8EE;
            margin-bottom: 20px;

            &__category {
                font-size: 12px;
                color: #358ED7;
                letter-spacing: 1px;
                text-transform: uppercase;
                text-decoration: none;
            }
            &__name {
                font-weight: 300;
                font-size: 52px;
                color: #43484D;
                letter-spacing: -2px;
            }
            &__price {
                font-weight: 300;
                font-size: 26px;
                color: #43484D;
                letter-spacing: -1px;

                & span:first-child {
                    margin-right: 2px;
                }
            }
            &__desc {
                font-size: 16px;
                font-weight: 300;
                color: #86939E;
                line-height: 24px;
            }
        }
        &__config {
            border-bottom: 1px solid #E1E8EE;

            & > div  {
                margin-bottom: 30px;

                & > span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #86939E;
                    margin-bottom: 20px;
                    display: inline-block;
                }
            }

            &__size {
                & button {
                    border: 2px solid #E1E8EE;
                    border-radius: 6px;
                    padding: 13px 20px;
                    font-size: 14px;
                    color: #5E6977;
                    background-color: #fff;
                    cursor: pointer;
                    transition: all .5s;

                    &:hover {
                        border: 2px solid #86939E;
                        outline: none;
                    }
                    &.selected {
                        border: 2px solid #86939E;
                        outline: none;
                    }
                }

            }
            &__color {
                &__choose {

                    & div {
                        display: inline-block;
                    }
                    & input[type=radio] {
                        display: none;

                        & + label span {
                            display: inline-block;

                            width: 40px;
                            height: 40px;

                            margin: -1px 4px 0 0;
                            vertical-align: middle;
                            cursor: pointer;
                            border: 2px solid #FFFFFF;
                            border-radius: 50%;
                            box-shadow: 0 1px 3px 0 rgba(0,0,0,0.33);
                        }

                        &:checked + label span {
                            background-image: url("./checkIcon.svg");
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }
                }
            }
            &__variations {
                &__choose {

                    & div {
                        display: inline-block;
                    }
                    & input[type=radio] {
                        display: none;

                        & + label span {
                            display: inline-block;

                            width: 40px;
                            height: 40px;
                            line-height: 40px;

                            margin: -1px 4px 0 0;
                            text-align: center;
                            vertical-align: middle;
                            cursor: pointer;
                            border: 2px solid #FFFFFF;
                            box-shadow: 0 1px 3px 0 rgba(0,0,0,0.33);
                        }

                        &:checked + label span {
                            background-color: #7fb8e6;
                        }
                    }
                }
            }
        }
        &__bottom {
            margin-top: 30px;

            & button {
                display: inline-block;
                background-color: #7DC855;
                border-radius: 6px;
                font-size: 16px;
                color: #FFFFFF;
                text-decoration: none;
                padding: 12px 30px;
                transition: all .5s;

                &:hover {
                    cursor: pointer;
                    background-color: #64af3d;
                }
                &:disabled {
                    cursor: not-allowed;
                    background-color: #9bb190;
                }
            }
        }

        @media (max-width: 940px) {
            & {
                flex-direction: column;
                justify-content: center;
                margin-top: 60px;
            }

            &__left,
            &__right {
                width: 100%;
            }

            &__left {
                padding: 0;
            }

            &__right {
                justify-content: center;
                text-align: center;

                margin-top: 20px;
            }
        }

        // @media (max-width: 535px) {
        //     .left-column img {
        //         width: 220px;
        //         top: -85px;
        //     }
        // }
    }
}