
.footer {
    display: flex;
    flex-direction: column;

    margin-top: 40px;

    & > div {
        margin-bottom: 10px;
    }

    &__links {
        display: flex;
        flex-direction: row;
        justify-content: center;

        & a {
            color: #444;
            margin: 10px;
            text-decoration: none;
        }
    }
    &__payments {
        display: flex;
        flex-direction: row;
        justify-content: center;

        & img {
            margin: 0px 5px;
            width: 38px;
            height: 24px;
        }
    }
    &__bottom {
        display: flex;
        flex-direction: row;
        justify-content: center;

        color: #86939E;
        font-size: 14px;
        font-weight: 400;

        margin-top: 10px;
        margin-bottom: 20px !important;
    }
}
