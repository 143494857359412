
.overlay {
    z-index: 10;

    position: absolute;

    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.7);
}

.cart__popup {
    $width: 460px;

    z-index: 11;
    display: flex;
    flex-direction: column;
    position: absolute;

    width: $width;
    height: auto;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background-color: white;

    @media (max-width: 940px) {
        width: 400px;
    }
    @media (max-width: 600px) {
        width: 66%;
    }

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        & span {
            margin-right: 5px;
            font-size: 30px;

            transition: transform 0.2s ease-in-out;

            &:hover {
                cursor: pointer;
                transform: rotate(90deg);
            }
        }
    }
    &__content {
        display: flex;
        flex-direction: column;

        margin: 10px 30px;

        &__msg {
            margin-top: 0;
            color: #7DC855;
        }
        &__bottom {
            width: 100%;
            margin-top: 15px;

            & button {
                width: 100%;

                font-weight: bold;
                text-transform: uppercase;

                margin-bottom: 10px;
                padding: 10px;
                border: solid 3px black;

                transition: all 0.2s ease-in-out;

                & a {
                    color: black;
                    text-decoration: none;
                }

                &:hover {
                    cursor: pointer;
                    background-color: #202020;
                    color: white;
                    border: solid 3px transparent;
                }
            }
        }
        &__product {
            display: flex;
            flex-direction: row;

            &__left {
                width: 40%;
                margin-right: 20px;

                & img {
                    width: 100%;
                }
            }
            &__right {
                width: 60%;

                & h2, & h3 {
                    margin-top: 0;
                    font-weight: normal;
                }
                & div:not(&__total) {
                    font-size: 14px;
                    font-weight: 400;
                    color: #86939E;
                    margin-top: 5px;
                }
                &__total {
                    font-size: 16px;
                    font-weight: bold;
                    color: #292d30;
                    margin-top: 15px;
                }
            }

            @media (max-width: 940px) {
                & {
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    // margin-top: 60px;
                }
                &__left,
                &__right {
                    width: 100%;
                }
                &__left > img {
                    width: 70%;
                }
            }
        }
    }
}
