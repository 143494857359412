
.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 1rem;

    margin: 0;
    padding: 0;

    &__holder {
        display: flex;
        width: 100%;
        justify-content: center;

        & > a {
            color: inherit;
            text-decoration: none;
        }
    }

    &__product {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        width: 350px;

        font-family: sans-serif;

        & img {
            width: calc(100% - 2*10px);
            padding: 10px;
        }
        & span {
            width: calc(100% - 2*5px);
            padding: 5px;
            text-align: center;
        }
        &__name {
            font-weight: bold;
        }
        &__price {
            font-weight: 200;
        }
    }
}
