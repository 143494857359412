
.cart__page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    overflow-x: hidden;
    overflow-y: scroll;

    &__content {
        display: flex;
        flex-direction: column;

        margin-bottom: 40px;

        text-align: center;

        & h1 {
            margin-bottom: 50px;
        }

        &__main {
            display: flex;
            flex-direction: column;

            margin-left: 10%;
            margin-right: 10%;
        }
        &__empty {
            & div:first-child {
                font-size: 26px;
                margin-bottom: 50px;
            }
            & a {
                font-size: 30px;
                color: #7DC855;
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }

    &__total {
        margin-top: 40px;
        font-size: 30px;
        font-weight: bold;

        & span {
            font-weight: 300;
        }
    }

    &__product {
        display: flex;
        flex-direction: row;
        justify-content: center;

        padding-top: 40px;
        padding-bottom: 40px;

        border-bottom: solid 1px gray;

        &:first-child {
            border-top: solid 1px gray;
        }

        &__info {
            display: flex;
            flex-direction: row;
            margin-right: 40px;

            & img {
                width: 200px;
                height: 100%;
                object-fit: contain;
            }
            & > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 170px;

                margin-left: 10px;

                text-align: left;
            }
            & h2, & h3 {
                margin-top: 0;
                font-weight: normal;
            }
        }
        &__config {
            display: flex;
            flex-direction: row;

            justify-content: space-between;

            & > div {
                display: flex;
                flex-direction: column;
                justify-content: center;

                margin-left: 10px;
                margin-right: 10px;
            }
            &__quantity {
                & > span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #86939E;
                    margin-top: -18px;
                }
            }
            &__price {
                font-size: 24px;
                font-weight: bold;
            }
            &__remove > svg {
                width: 30px;
                height: 30px;
                fill: black;

                transition: fill 0.2s ease-in-out;

                &:hover {
                    cursor: pointer;
                    fill: red;
                }
            }
        }

        @media (max-width: 940px) {
            flex-direction: column;
            margin-left: 10%;
            margin-right: 10%;
            margin-bottom: 40px;

            &__info {
                flex-direction: column;
                align-items: center;
                margin-right: 0;

                & > div {
                    margin-top: 20px;
                }
            }
            &__config {
                margin-top: 40px;
            }
        }
    }
}
