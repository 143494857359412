
$header-top-height: 30px;
$header-logo-height: 200px;
$header-nav-height: 50px;

.header {
    display: flex;
    flex-direction: column;

    color: black;

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        width: 100%;
        height: $header-top-height;

        margin-top: 5px;

        & a {
            display: inherit;
            color: black;
            text-decoration: none;
        }

        &__socials {
            display: flex;
            flex-direction: row;
            justify-content: center;

            margin-left: 10px;
        }
        &__incart {
            height: $header-top-height;
            line-height: $header-top-height;
            vertical-align: middle;
        }
        &__cart {
            margin-right: 10px;
        }

        & img {
            padding: 3px;
            height: $header-top-height - 6px;
        }
    }

    &__logo {
        display: flex;
        flex-direction: row;
        justify-content: center;

        width: 100%;
        height: $header-logo-height;

        margin-top: 20px;
        margin-bottom: 40px;

        & img {
            height: $header-logo-height;
        }
    }

    &__nav {
        display: flex;
        flex-direction: row;
        justify-content: center;

        width: 100%;
        height: $header-nav-height;

        & div {
            padding-left: 20px;
            padding-right: 20px;

            & a {
                color: black;
                text-decoration: none;
            }
        }
    }
}